import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout/layout.js"
// import Image from "../components/image"
// import SEO from "../components/seo"
import BulletPoint from "../../components/shared/bullet-point.js"
import SkillPoint from "../../components/shared/skill-point"

const LifePharmacyMobileApp = () => (

  <Layout>
    {/* <SEO title="Home" /> */}

    <div className="pb-0 pb-sm-2">
      <h1 className="title title--h1 text-center">Life Pharmacy Mobile App</h1>
      <div
        className="gallery-grid__image-wrap col-md-12"
        style={{ paddingBottom: "10px" }}
      >
        <img
          className="gallery-grid__image cover lazyload"
          src={"../assets/img/image_01.jpg"}
          alt=""
        />
      </div>
      <p>
        LIFE commenced its journey as a retail pharmacy group in 1996 and the
        strong impulse to perform has nurtured its growth over the last twenty
        years. Starting with one store, at present the organization has over 150
        retail outlets consisting of Pharmacies, Healthcare Hypermarkets, Health
        and Wellness stores catering to an average annual customer base of more
        than ten million walk-ins.
      </p>
      <p>
        I was part of an ambitious project to design the LIFE Mobile experience
        for their Customers to order Prescription Drugs/Products.
      </p>

      <h4>High level goals</h4>
      <ol>
        <li>Make it fast and easy to order for everyone.</li>
        <li>Create easy way for customers to use Vouchers.</li>
      </ol>
    </div>

    {/*// <!-- Experience -->*/}

    <div className="pb-0">
      <div className="row">
        <div className="col-12 col-lg-12">
          <h2 className="title title--h3">
            {/*<img*/}
            {/*  className="title-icon"*/}
            {/*  src="../assets/icons/icon-experience.svg"*/}
            {/*  alt=""*/}
            {/*/>*/}
            My Role
          </h2>
          <p>
            I worked on the UX part of the app and collaborated with designers
            and Tech team, Created Wireframe Prototypes and High Fidelity
            Prototypes for client testing. Worked with Front-end team and built
            some UI screens and widgets in Flutter.
            <br />
            The app launched on January 31, 2019.
          </p>

          <h2 className="title title--h3">
            {/*<img*/}
            {/*  className="title-icon"*/}
            {/*  src="../assets/icons/icon-experience.svg"*/}
            {/*  alt=""*/}
            {/*/>*/}
            Positive results and much more to do
          </h2>
          <p>
            The design of the LIFE Pharmacy app on iOS and Android has had a positive impact on the order experience on users.
          </p>


          <div
            className=" col-md-12"
            style={{ paddingBottom: "10px", backgroundColor: "#25338c", padding: "20px 0px" }}
          >

            {[...Array(11)].map((x, i) =>
              <img
                key={i}
                className="lazyload col-md-4"
                data-zoom
                src={`../assets/img/life-screens/${i+1}.png`}
                alt=""
              />
            )}

          </div>


          <div
            className="gallery-grid__image-wrap col-md-12"
            style={{ paddingBottom: "10px" }}
          >
            {/*<img*/}
            {/*  className="gallery-grid__image cover lazyload"*/}
            {/*  src={"../assets/img/image_01.jpg"}*/}
            {/*  alt=""*/}
            {/*/>*/}
          </div>

          <div className="timeline">
            {/*// <!-- Item -->*/}

            {/*<BulletPoint*/}
            {/*  title={"UX/UI & Front-end Developer"}*/}
            {/*  subTitle={"Group Rhinos"}*/}
            {/*  year={"(2018 — Present)"}*/}
            {/*  description={*/}
            {/*    "Working as UX/UI Designer. Hold stakeholder meetings and discuss sketched/inspiration ideas. Do user research and create User epics and user stories. Create User flows and wireframes. Create Prototypes and have it tested with client and stakeholders. Create UI components in Flutter(Dart) or ReactJS."*/}
            {/*  }*/}
            {/*/>*/}
          </div>



        </div>
      </div>
    </div>

    {/* <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default LifePharmacyMobileApp
